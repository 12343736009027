:root {
  /* one grey */
  --one-grey-1000: #232323;
  --one-grey-button: var(--one-grey-1000);

  --one-grey-900: #323232;
  --one-grey-primary: var(--one-grey-900);

  --one-grey-600: #5a6376;
  --one-grey-secondary: var(--one-grey-600);

  --one-grey-400: #8c96a8;

  --one-grey-200: #c5cad5;
  --one-grey-text-on-dark: var(--one-grey-200);

  --one-grey-25: #fafafa;

  /* one green */
  --one-green-800: #00694a;

  --one-green-600: #1b7c53;
  --one-green-primary: var(--one-green-600);
}

html,
body {
  font-family: "Euclid Circular A", Arial, sans-serif !important;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Light.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Light.woff") format("woff"),
    url("./fonts/EuclidCircularA-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-LightItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-LightItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Regular.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Regular.woff") format("woff"),
    url("./fonts/EuclidCircularA-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-RegularItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-RegularItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Medium.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Medium.woff") format("woff"),
    url("./fonts/EuclidCircularA-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-MediumItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-MediumItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Semibold.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Semibold.woff") format("woff"),
    url("./fonts/EuclidCircularA-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Bold.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Bold.woff") format("woff"),
    url("./fonts/EuclidCircularA-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-BoldItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-BoldItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* One NZ design system / typography */

/* h1 */
.one-display-large {
  font-weight: 500;
  font-size: 57px;
  line-height: 68px;
  letter-spacing: -1px;
}

.one-display-medium {
  font-weight: 500;
  font-size: 48px;
  line-height: 55px;
  letter-spacing: -0.75px;
}

.one-display-small {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.5px;
}

/* h2 */
.one-heading-large {
  font-weight: 500;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.75px;
}

.one-heading-small {
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.25px;
}

/* h1 subtitle,  */
.one-body-large {
  font-weight: 300;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: -0.25px;
}

/* h2 subtitle */
.one-body-medium {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.one-label-large {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.one-label-medium {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0px;
}

.one-label-small {
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0px;
}

.one-link-large {
  color: var(--one-green-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.one-link-large:hover,
.one-link-large:focus {
  color: var(--one-green-800);
}

.one-link-large:focus {
  outline: 2px solid var(--one-green-800);
  border-radius: 5px;
}

/*.custom-radio-select {*/
/*  border:none !important;*/
/*}*/

input[type="radio"]:checked {
  outline: none;
  background-color: var(--one-green-primary) !important;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiBmaWxsPSIjMUI3QzUzIgogICAgIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxNSAxNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAgMTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTE0LDcuNWMwLDMuNTg5OS0yLjkxMDEsNi41LTYuNSw2LjVTMSwxMS4wODk5LDEsNy41UzMuOTEwMSwxLDcuNSwxUzE0LDMuOTEwMSwxNCw3LjV6Ii8+Cjwvc3ZnPg==") !important;
}

input[type="checkbox"]:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  background-color: #000;
  cursor: pointer;
}

input[type="checkbox"]:checked:before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #000;
  position: relative;
  left: 1px;
  top: -4px;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.bold {
  font-weight: bold;
}

.background-ebeb-gray {
  background-color: #ebebeb;
}

/**
BEGIN FORM STYLING
 */

/** form input border color */
.form-control,
.form-check-input {
  height: 45px;
  background-color: white !important;
  border: 1px solid var(--one-grey-400) !important;
  border-radius: 5px !important;
}

.standaloneSearchBox > div {
  width: 100%;
}

/** form and input background color */
#formStepOne > div > div,
#formStepTwo > div > div,
#formStepThree > div > div {
  background-color: #ebebeb !important;
}

/** button styling */
form button[type="submit"],
form button[type="reset"],
form button[type="button"] {
  border-radius: 100px;
  min-width: 120px;
}

form button[type="submit"] {
  background-color: var(--one-green-primary);
  color: white;
  padding: 10px 22px;
  border: none;
}

form button[type="reset"],
form button[type="button"] {
  background-color: transparent;
  color: var(--one-grey-900);
  border: 1px solid var(--one-grey-900);
  padding: 9px 22px;
}

.error {
  color: red !important;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0px;
}

/* make column heights even */
.col-md-4 {
  flex: 0 0 33.3333333%;
  max-width: 33.3333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

/* style google map infoWindow */
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after {
  background: black;
  color: white;
}

#formStepOne .card,
#formStepTwo .card,
#formStepThree .card {
  border: none;
}
#formStepOne .card > div,
#formStepTwo .card > div,
#formStepThree .card > div {
  padding: 2rem !important;
}

@media screen and (min-width: 992px) {
  .steps-wrapper {
    max-width: 1180px !important;
  }

  .map-form-wrapper {
    height: 760px;
    max-width: 1180px !important;
    border-radius: 10px;
    overflow: hidden !important;
    padding: 0 !important;
  }

  .map-form-wrapper > *,
  .map-form-wrapper > * > *,
  .map-form-wrapper > * > * > * {
    height: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .steps-wrapper {
    padding: 0 !important;
  }

  .steps-wrapper > h2 {
    margin-top: 40px;
    text-align: start;
    font-size: 24px;
    color: gray;
  }

  .map-form-wrapper {
    height: auto;
    padding: 0 !important;
  }

  .map-wrapper {
    aspect-ratio: 1;
  }

  .map-wrapper > * {
    height: 100% !important;
    z-index: 10 !important;
    box-shadow: 2px 2px 1px #eee;
  }

  .form-wrapper .card-body {
    padding: 60px 20px 20rem 20px !important;
  }
}
