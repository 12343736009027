.container {
  background-color: transparent;
  margin-top: 60px;
  margin-bottom: 60px;
}

.faqsWrapper {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
}

.faqsWrapper h3{
  margin-bottom: 32px !important;
}


.header > button {
  font-weight:500;
  font-size:19px;
  line-height: 29px;
  letter-spacing: -0.25px;

  border-top: 1px solid var(--one-grey-200) !important;
  width: 100% !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding-left: 0;
  padding-right:0;

  display: flex;
  flex-direction: row;
  align-items: start;
}

.header button::before {
  background-image: none;
  content: url("../../images/chevron-down-circled.svg");

  transform: translateY(3px);

  width: 20px;
  height:20px;

  margin-right: 8px;
}

.header button[aria-expanded='true']::before {
  content: url("../../images/chevron-up-circled.svg");
}

.header button::after {
  display:none
}

/* Remove the default blue color for title */
.header button:not(.collapsed) {
  color: inherit !important;
}

.body {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;

  background-color: transparent !important;
  padding:1rem 0;
}

.body a {
  color: var(--one-green-primary);
  font-weight: 500;
}