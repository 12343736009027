.component {
  margin-bottom: 40px;
}

.topMiniNavbar {
  background-color: var(--one-grey-primary) !important;
  padding: 0 !important;
}

.topMiniNavbar > div > div {
  padding-left: 76px;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
}

.topMiniNavbar a {
  padding:4px 16px !important;
  line-height: 20px !important;
  font-size:15px;
  letter-spacing: -0.25px;
}

.topMiniNavbar a:not(.topMiniNavbarActive) {
  color: var(--one-grey-text-on-dark) !important;
}

.topMiniNavbar a:hover,
.topMiniNavbarActive {
  background-color: white !important;
  color: var(--one-grey-primary) !important;
}

.brandNavbarWrapper {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px lightgray;
  background-color: white;
}

.subNavbarWrapper {
  visibility: hidden;
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: hsla(0, 0%, 100%, 0.8);
  z-index: 10;
  border-top: 1px solid darkgray;
  padding: 0;
}

.subNavbarWrapper > * > * {
  padding: 0 0 0 64px;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

.subNavbarWrapper > * > * > a {
  position: relative;
  white-space: nowrap;

  font-weight: normal;
  font-size: 22px;
  line-height: 27.9px;
  letter-spacing: -0.65px;

  color: var(--one-grey-primary);

  padding: 16px 24px !important;
}

.subNavbarWrapper > * > * > a:hover {
  background-color: white;
  color: var(--one-green-primary);
}

.subNavbarWrapper > * > * > a:hover .navDropdown {
  visibility: visible;
}

.navDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  white-space: nowrap;
  background-color: white;
}

.navDropdown > a {
  padding: 16px 24px !important;
  text-align: left;
}

.navDropdown > a:hover {
  color: var(--one-green-primary);
}

.brandNavbar {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.brandNavbarNavItems > a {
  font-weight: normal;
  font-size: 22px;
  line-height: 27.9px;
  letter-spacing: -0.65px;

  color: var(--one-grey-primary);

  padding: 22px 24px !important;
}

.brandNavbarNavItems > a:hover,
.brandNavbarNavItems > a:active,
.brandNavbarNavItems > a:focus {
  color: var(--one-green-600);
  box-shadow: inset 0 -4px var(--one-green-600);
}

.mobileNavDropDownWrapper {
  visibility: hidden;
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  background-color: white;
  height: calc(100vh - 100% - 1px);
  z-index: 100;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0 lightgray;
}

.mobileNavDropDownWrapper > * {
  width: 100% !important;
}

.mobileNavDropDownWrapper ul {
  padding-left: 0;
}

.mobileNavDropDownWrapper li {
  list-style: none;
  text-decoration: none;
  background-color: var(--one-grey-400);
  margin-top: 10px;
}

.mobileNavDropDownWrapper a:hover {
  color: var(--one-grey-primary);
}

.mobileNavDropDownWrapper ul a {
  width: 100%;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  color: white;
}

.mobileNavDropDownWrapper li {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconHome {
  margin-left:1rem;
  width: 48px;
  height:48px;
}

.brandNavbarIcons > button {
  border:none;
  background-color: transparent;
}

.brandNavbarIcons > button > svg {
  margin-right: 12px !important;
  margin-left: 12px !important;
  /*height: 24px !important;*/
  width: 24px;
  height: 24px;
  color: #666;
}

.brandNavbarIcons > button:hover svg,
.brandNavbarIcons > button:active svg,
.brandNavbarIcons > button:focus svg {
  color: var(--one-green-600);
}

.homeHeaderImageWrapper {
  height: 400px;
  position: relative;
  color: #fff;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homeHeaderImageWrapper > div {
  margin: auto;
  width: 100%;
  max-width: 1180px;
}

.homeHeaderWrapper {
  position: relative;
  animation: ease-out 1s slide-up;
}

@keyframes slide-up {
  from {
    top: 400px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 100%;
  }
}

.homeHeaderImageWrapper h1 {
  font-size: 57px;
  line-height: 68px;
  letter-spacing: -1px;
  font-weight: 500;
  text-align: left;
  max-width: 592px;
}

.homeHeaderImageWrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  animation: ease-out 1s light-up;
}

@keyframes light-up {
  from {
    filter: brightness(0);
  }
  to {
    filter: brightness(1);
  }
}

.homeHeaderImageWrapper,
.homeHeaderImageWrapper img {
  padding: 0 !important;
  margin: 0 !important;
}

.breadcrumbNav {
  background-color: var(--one-grey-25) !important;
  border:1px solid var(--one-grey-200);
  box-sizing: border-box;
}

.breadcrumbsNavWrapper {
  margin: 24px auto;
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.breadcrumbsNavItems {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breadcrumbsNavItems > * {
  margin-right: 20px;
  color: var(--one-grey-primary);
}

.breadcrumbNav a {
  text-decoration: none;
}

.breadcrumbNav a:hover,
.breadcrumbNav a:active,
.breadcrumbNav a:focus {
  color: var(--one-green-600);
}

.breadcrumbNavChevron {
  font-size: 16px;
}

.breadcrumbNav button {
  border-radius: 20px;
  width: 134px;
  color: #fff;
  font-size: 16px;
  border-color: #fff;
  background-color: #25282b;
}

.smLinkGroup {
  background-color: transparent;
  border-bottom: none;
  border-top: 0.5px white solid !important;
}

.smLinkGroupHeader {
  width: 100%;
  background-color: transparent !important;
  display: flex;
  justify-content: space-between;
}

.smLinkGroupHeader button {
  width: 100%;
  background-color: transparent;
  color: black !important;
  font-size: 20px;
  font-weight: normal !important;
  margin-right: 0;
}

.smLinkGroupHeader button:not(.collapsed) {
  background-color: transparent;
  color: black !important;
  box-shadow: none;
}

.smLinkGroupHeader button::after {
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  content: url("../../images/chevron-down-one-green.svg");
  width: 30px;
  height: 30px;
  transform-origin: center;
}

.smLinkGroupHeader button:not(.collapsed)::after {
  background-image: none;
}

.smLinkGroupBody .smLinkGroupHeader button {
  padding: 0 !important;
}

.smLinkGroupBody .smLinkGroupHeader button::after {
  margin-top: -15px;
}

.smLinkGroupBody {
  border-top: none !important;
}

.smLinkGroupBody a {
  font-size: 20px;
  text-align: left;
  padding: 0 0 20px 20px;
}

.smLinkGroupBody .smLinkGroupBody a {
  padding: 5px 0 5px 20px;
  background-color: #eee;
}

.chevronRemoved > button::after {
  display: none !important;
}

.homeHeaderWrapper > p {
  margin-top: 30px;
}

.heroButton {
  border: 1px solid white;
  background: transparent;
  color: white;
  text-decoration: none;
  border-radius: 10px;

  padding: 10px 40px;
  
  font-weight:500;
}

.heroButton:hover,
.heroButton:active,
.heroButton:focus {
  background-color: var(--one-green-primary);
  color:white;
  border:none;
}

@media screen and (max-width: 991px) {
  .homeHeaderImageWrapper > div {
    margin: 48px auto;
    width: 100%;
    max-width: 1180px;
  }

  @keyframes slide-up {
    from {
      top: 240px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 100%;
    }
  }

  .homeHeaderImageWrapper h1 {
    font-size: 52px;
    text-align: center;
    max-width: 100%;
  }

  .homeHeaderImageWrapper h2 {
    font-size: 20px;
  }

  .breadcrumbsNavWrapper {
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .breadcrumbNavButtonWrapper {
    margin-top: 20px;
    align-self: start;
  }

  .iconHome {
    height: 32px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 576px) {
  .mobileNavDropDownWrapper {
    width: 320px;
  }
}
