.anchor {
  text-underline-color: black;
  text-underline-offset: 3px;
  font-weight: bold;
  color: #555;
}

.anchor:hover,
.anchor:active,
.anchor:focus {
  color: var(--one-green-primary);
}

.descriptionTextArea {
  background-color: white !important;
  border-radius: 5px !important;
  border: 1px solid var(--one-grey-400) !important;
  height: 100px;
}

.socialIconsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:10px;
}

.socialIconsWrapper > * {
  width: 44px;
  height:44px;
  padding: 4px;
}

.socialIconsWrapper > * > img {
  width: 100%;
  height: 100%;
  color: var(--one-grey-400)
}
