.draggableHint {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  height: auto !important;
}

.draggableHint p {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  white-space: nowrap;
  font-weight: 500;
  color: white;
}
.mapBox {
  position: relative;
}
