.stepsWrapper{
  display:flex;
  flex-direction:row;
  padding: 0;
}

.tilesWrapper{
  width: 100%;

  display:flex;
  flex-direction:row;
  padding: 0;
  gap:0 30px;
}

.stepContainer {
  border-top: 2px solid #999;
}

.topBorderHighlighted {
  border-top: 4px solid var(--one-green-primary);
}

.bottomBorderHighlighted {
  border-bottom: 4px solid var(--one-green-primary) !important;
}

.bottomBordered {
  border-bottom: 2px solid #999;
}

.text{
  margin-left:10px;
}

.icon,
.currentIcon {
  width: 60px;
  height: 60px;
}

.currentIcon {
  color: var(--one-green-primary);
}

.icon {
  color: #999;
}

.copy {
  font-weight: 300;
  line-height: 26px;
  font-size: 22px;
}

.smCurrentTitle {
  color: var(--one-green-primary);
}

@media screen and (max-width: 991px) {
  .stepsWrapper {
    flex-direction: column;
  }

  .copy {
    font-size: 18px;
  }

  .currentIcon {
    width: 32px;
  }

  .title,
  .currentTitle {
    font-size: 26px;
  }

  .steps-wrapper > * {
    padding-left: 0 !important;
  }
}