.component {
  background-color: var(--one-grey-primary);
  padding-top: 20px;
}

.component a,
.component a:hover,
.component a:focus,
.component a:active {
  text-align: left;
  color: #d7d7d7;
}

.component a {
  color: white;
}

.footerRow {
  margin: 0 auto;
  margin-bottom: 60px !important;
  max-width: 1180px;
}

.linkGroup {
  margin-bottom: 40px;
}

.superLink {
  font-size: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.superLinkChevron {
  margin-top: -8px;
  margin-left: 12px;
  font-size: 48px;
  font-weight: lighter;
}

.subLink {
  font-size: 18px;
}

.superLink,
.subLink {
  font-weight: 300;
}

.copyright {
  color: #d7d7d7;
}

.copyright p {
  text-align: right;
  width: 100%;
}

.smLinksBlock {
  margin-top: 20px;
  display: none;
}

.smLinkGroup {
  background-color: transparent;
  border-bottom: none;
  border-top: 0.5px white solid !important;
}

.smLinkGroupHeader {
  width: 100%;
  background-color: transparent !important;
}

.smLinkGroupHeader button {
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 18px;
}

.smLinkGroupHeader button:not(.collapsed) {
  background-color: transparent;
  color: white;
  box-shadow: none;
}

.smLinkGroupHeader button::after {
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  content: url("../../images/chevron-down-white.svg");
  width: 30px;
  height: 30px;
  transform-origin: center;
}

.smLinkGroupHeader button:not(.collapsed)::after {
  background-image: none;
}

.smLinkGroupBody {
  border-top: none !important;
}

.smLinkGroupBody a {
  font-size: 18px;
  padding: 0 0 20px 0;
}

@media screen and (max-width: 991px) {
  .smLinksBlock {
    display: block;
  }

  .hiddenOnSmScreen {
    display: none;
  }

  .copyright {
    margin-left: 16px;
  }

  .copyright p {
    text-align: left;
  }
}
